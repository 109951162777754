import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/ecom2.PNG";
import IMG2 from "../../assets/dap2.PNG";
import IMG3 from "../../assets/venue.PNG";
import IMG4 from "../../assets/cloudup.PNG";
import IMG5 from "../../assets/teletris.PNG";
import IMG6 from "../../assets/haramain.PNG";
import IMG7 from "../../assets/classified.PNG";
import IMG8 from "../../assets/socialapp.jpg";
import IMG9 from "../../assets/moviesapp.jpg";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Ecommerce Store in React Js and Strapi",
    demo: "https://www.youtube.com/watch?v=Fn7da32i_Yg",
  },
  {
    id: 2,
    image: IMG2,
    title: "Stripe Subscription on  Venues and Events Using Webhook",
    demo: "",
  },
  {
    id: 3,
    image: IMG3,
    title: "Managing Events,Venues and its advertising , bidding on it",
    demo: "https://www.youtube.com/watch?v=HfPrxLSIM7o",
  },
  {
    id: 4,
    image: IMG4,
    title: "Company Website",
    demo: "https://www.loom.com/share/5a1069c979f14ac7b4675cbd27085123",
  },
  {
    id: 5,
    image: IMG5,
    title: "Funeral Home Website",
    demo: "https://www.loom.com/share/a70688376cbf4ba986a6d39bfe0ca5c4",
  },
  {
    id: 6,
    image: IMG6,
    title: "Haramain Bookings",
    demo: "https://haramain-bookings.com",
  },
  {
    id: 7,
    image: IMG7,
    title: "Classified , Web application and Mobile app",
    demo: "",
  },

  {
    id: 6,
    image: IMG8,
    title: "Movies App",
    demo: "",
  },
  {
    id: 6,
    image: IMG9,
    title: "Social App",
    demo: "",
  },
];
const Potfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img
                  src={image}
                  alt={title}
                  style={{ maxHeight: 200, objectFit: "cover" }}
                />
              </div>
              <h3>{title}</h3>
              {demo && (
                <div className="portfolio__item-cta">
                  <a href={demo} target="_blank" className="btn btn-primary">
                    Live Demo
                  </a>
                </div>
              )}
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Potfolio;
